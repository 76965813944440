import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
    apiKey: "AIzaSyD7gdjUkhZcpiiQj_Cpfm5P5uRg9ihMxws",
    authDomain: "pickled-sandbox.firebaseapp.com",
    projectId: "pickled-sandbox",
    storageBucket: "pickled-sandbox.appspot.com",
    messagingSenderId: "484914548771",
    appId: "1:484914548771:web:2d3eac8bd6d00ae34c2196",
    measurementId: "G-YB1KTFRVC4"
};

// init firebase
initializeApp(firebaseConfig)

// init services
const db = getFirestore()
const auth = getAuth()
const stor = getStorage()
const functions = getFunctions()

// Initialize Providers
const googleProvider = new GoogleAuthProvider();

const twitterProvider = new TwitterAuthProvider();

export { db, auth, stor, functions, googleProvider, twitterProvider }