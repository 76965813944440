<template>
  <nav v-if="user" class="fixed-navbar">
    <div class="menuOuter">
      <div class="menuHeader">
        <div @click="handleHome" class="menuItem">
          <i class="fa-solid fa-pickleball"></i>
          <br />{{ $t('events') }}
        </div>
        <!-- <div @click="handleLocations" class="menuItem">
          <i class="fa-solid fa-location-dot"></i>
          <br />{{ $t('locations') }}
        </div> -->
        <div @click="handleVenues" class="menuItem">
          <i class="fa-solid fa-map-location-dot"></i>
          <br />{{ $t('venues') }}
        </div>
        <div @click="handleProfile" class="menuItem">
          <i class="fa-solid fa-user"></i>
          <br />{{ $t('profile') }}
        </div>
        <div @click="handleAlerts" class="menuItem">
          <i class="fa-solid fa-bell"></i>
          <br />{{ $t('alerts') }}
          <span v-if="docCount" class="menuSuper">{{ docCount }}</span>
        </div>
        <div @click="handleMessages" class="menuItem">
          <i class="fa-solid fa-messages"></i>
          <br />{{ $t('messages') }}
          <span v-if="msgCount" class="menuSuper">{{ msgCount }}</span>
        </div>
        <div @click="handleLeagues" class="menuItem" style="min-width: 80px;">
          <i class="fa-solid fa-people-group"></i>
          <br />{{ $t('leagueMenu') }}
        </div>
        <div @click="handlePlayers" class="menuItem">
          <i class="fa-solid fa-people"></i>
          <br />{{ $t('players') }}
        </div>
        <!-- <div v-if="isPoppi" @click="handlePoppi" class="menuItem">
          <i class="fa-solid fa-flower"></i>
          <br />POPPI
        </div>
        <div v-if="isPoppi" @click="handleMedia" class="menuItem">
          <i class="fa-regular fa-photo-film"></i>
          <br />Media
        </div> -->
        <div @click="handleSupport" class="menuItem">
          <i class="fa-solid fa-face-smiling-hands"></i>
          <br />{{ $t('support') }}
        </div>

      </div>

    </div>

    <div class="menu-marquee">
      <Vue3Marquee :pauseOnClick="true" :duration="40" :clone="true">
        <span v-if="leaderboard">
          <span v-for="(word, index) in leaderboard" :key="index" class="word-style" :class="{ word: true, odd: index % 2 === 0, even: index % 2 === 1 }">
            {{ word }}
          </span>
        </span>
      </Vue3Marquee>
    </div>
  </nav>
</template>

<script>
import { db, } from "@/firebase/config";
import { ref, onMounted } from "vue";
import getUser from "@/composables/getUser";
import { useRouter } from "vue-router";
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
import getAlertCount from "@/composables/getAlertCount"
import getMessageCount from "@/composables/getMessageCount"
import getPlayerRecent from "@/composables/getPlayerRecent";
import getFanatics from "@/composables/getFanatics";
import getTalentScouts from "@/composables/getTalentScouts";
import getPartyPlanners from "@/composables/getPartyPlanners";
import getLargestEvents from "@/composables/getLargestEvents";
// import reloadPage from "@/composables/reloadPage";
// import addLog from "@/composables/addLog"
import { doc, getDoc } from "firebase/firestore";
import { useI18n } from 'vue-i18n';
import Cookies from "js-cookie";



export default {
  setup() {
    // new preload spinner, this is temp until canvas option available
    let preloadGif = new Image();
    preloadGif.src = require('@/assets/BallSpin.gif'); // Corrected path resolution



    const { user, user_slug, user_name, waitForUserSlug } = getUser()

    const router = useRouter();
    const { locale } = useI18n();
    // const language = ref(locale.value)

    // get the count of unread alerts to display in menu
    const { docCount } = getAlertCount(user_slug.value)
    const { msgCount } = getMessageCount(user_slug.value)
    const playerName = ref(user_name.value)
    const playerRec = ref([])
    const playerSkill = ref('0')
    const playerHome = ref('')
    const playerHomeReady = ref(false)
    const attending = ref(false)
    const friend = ref(false)
    const fav = ref(false)
    const leaderboard = ref([])
    const currentTime = ref('');
    const isPoppi = ref(false)
    const loadLanguagePreference = () => {
      const savedLanguage = Cookies.get("language");
      if (savedLanguage) {
        locale.value = savedLanguage;
      }
    };
    onMounted(() => {
      loadLanguagePreference();
    });


    // get current time
    const getCurrentTime = () => {
      const now = new Date();
      let hours = now.getHours();
      let minutes = now.getMinutes();
      let seconds = now.getSeconds();

      // Add leading zeros for hours, minutes, and seconds if they are less than 10
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      currentTime.value = `${hours}:${minutes}:${seconds}`;
      currentTime.value = `${hours}:${minutes}:${seconds}`;
    };

    // get new players
    const newPlayers = ref('')
    const loadNewPlayers = async () => {
      let res = await getPlayerRecent()
      newPlayers.value = res
      newPlayers.value = newPlayers.value.slice(0, newPlayers.value.length - 2)

      switch (locale.value) {
        case 'es':
          leaderboard.value.push("Bienvenidos nuevos jugadores!:", newPlayers.value)
          break;
        default:
          leaderboard.value.push("Welcome new players!:", newPlayers.value)
      }

    }
    loadNewPlayers()

    // get fanatics
    const fanatics = ref('')
    const loadFanatics = async () => {
      let res = await getFanatics()
      fanatics.value = res
      fanatics.value = fanatics.value.slice(0, fanatics.value.length - 2)
      switch (locale.value) {
        case 'es':
          leaderboard.value.push("Jugadores mas activos:", fanatics.value)
          break;
        default:
          leaderboard.value.push("Most Active Players:", fanatics.value)
      }
    }
    loadFanatics()

    // get talent scouts
    const talentScouts = ref('')
    const loadTalentScouts = async () => {
      let res = await getTalentScouts()
      talentScouts.value = res
      talentScouts.value = talentScouts.value.slice(0, talentScouts.value.length - 2)
      // switch (locale.value) {
      //   case 'es':
      //     leaderboard.value.push("Mejores reclutadores:", talentScouts.value)
      //     break;
      //   default:
      //     leaderboard.value.push("Top Recruiters:", talentScouts.value)
      // }


    }
    loadTalentScouts()

    // get party planners
    const partyPlanners = ref('')
    const loadPartyPlanners = async () => {
      let res = await getPartyPlanners()
      partyPlanners.value = res
      partyPlanners.value = partyPlanners.value.slice(0, partyPlanners.value.length - 2)

      switch (locale.value) {
        case 'es':
          leaderboard.value.push("La mayoría de los eventos:", partyPlanners.value)
          break;
        default:
          leaderboard.value.push("Top Event Planners:", partyPlanners.value)
      }

    }
    loadPartyPlanners()

    // get biggest balls
    const biggestBalls = ref('')
    const loadBiggestBalls = async () => {
      let res = await getLargestEvents()
      biggestBalls.value = res
      biggestBalls.value = biggestBalls.value.slice(0, biggestBalls.value.length - 2)
      switch (locale.value) {
        case 'es':
          leaderboard.value.push("Eventos más épicos:", biggestBalls.value)
          break;
        default:
          leaderboard.value.push("Most Epic Events:", biggestBalls.value)
      }
    }
    loadBiggestBalls()

    // DEBT: should be composable!  get player record
    const getPlayer = async () => {
      if (user_slug.value) {
        let results = []
        const qPly = doc(db, "players", user_slug.value);
        const snapPly = await getDoc(qPly);
        if (snapPly.exists()) {
          // console.log("Player found.");
          // console.log("Document data:", snapPly.data());
          playerRec.value = snapPly.data()
          playerSkill.value = snapPly.data().skillSelf
          playerHome.value = snapPly.data().homeLocation
          playerHomeReady.value = snapPly.data().homeLocationReady
          if (snapPly.data().favToggle) {
            // console.log("Fav toggle ON");
            fav.value = true
          }
          if (snapPly.data().friendToggle) {
            // console.log("Friend toggle ON");
            friend.value = true
          }
          if (snapPly.data().attendingToggle) {
            // console.log("Attending toggle ON");
            attending.value = true
          }
          if (snapPly.data().poppi) {
            // console.log("Part of POPPI program");
            isPoppi.value = true
          }

        } else {
          // doc.data() will be undefined in this case
          // console.log("Player not found!");
        }
      }
    };
    getPlayer();

    const handlePoppi = () => {
      router.push({ name: "poppi" });
    };
    const handleMedia = () => {
      router.push({ name: "media" });
    };

    const handleProfile = () => {
      router.push({ name: "player" });
    };
    const handleHome = () => {
      router.push({ name: "home" });
    };
    // const handleLocations = () => {
    //   router.push({ name: "locations" });
    // };

    const handleVenues = () => {
      router.push({ name: "venues" });
    };

    const handleSupport = () => {
      router.push({ name: "support" });
    };
    const handleLeagues = () => {
      if (playerHome.value) {
        router.push({ name: "leagues", params: { locId: playerHome.value } })
      } else {
        // router.push({ name: "locations", params: { message: '**No home location set**' } });
        alert('Please set your home location')
        router.push({ name: "venues" });
      }
    };

    const handleAlerts = () => {
      router.push({ name: "alerts" });
    };

    const handleMessages = () => {
      router.push({ name: "messages" });
    };

    const handlePlayers = () => {
      if (playerHome.value) {
        router.push({ name: "players", params: { locId: playerHome.value } })
      } else {
        // router.push({ name: "locations", params: { message: '**No home location set**' } });
        alert('Please set your home location')
        router.push({ name: "venues" });
      }
    };

    const handleCreate = () => {
      router.push({ name: "createEvent" });
    };

    onMounted(() => {
      getCurrentTime();
      setInterval(getCurrentTime, 1000);
    });

    return {
      handleProfile,
      handleHome,
      handleCreate,
      // handleLocations,
      handleAlerts,
      handleSupport,
      handleMessages,
      handlePlayers,
      user,
      docCount,
      leaderboard,
      msgCount,
      playerName,
      playerSkill,
      playerHome,
      currentTime,
      playerHomeReady,
      handleLeagues,
      isPoppi,
      handlePoppi,
      handleVenues,
      handleMedia
    };
  },
  components: {
    Vue3Marquee,
  },
};
</script>

<style scoped>
.fixed-navbar {
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* Use a high z-index to ensure the navbar stays on top of other elements */
  /* Add any other styles you want for your navbar (background-color, height, etc.) */
}
</style>

