import { ref } from 'vue';
import { auth, db } from '@/firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, query, where, collection, getDocs } from 'firebase/firestore';
import slugify from 'slugify';

const user = ref(auth.currentUser);
const user_slug = ref('');
const user_name = ref('');

// Function to fetch user data from Firestore
const fetchUserData = async (_user) => {
  if (!_user) {
    // console.log('No user found.');
    user_slug.value = '';
    user_name.value = '';
    return;
  }

  try {
    // console.log('Fetching user data for user ID:', _user.uid);

    // Step 1: Query the playersPrivate collection for a document with matching userId
    const q = query(collection(db, 'playersPrivate'), where('userId', '==', _user.uid));
    const querySnapshot = await getDocs(q);
    // console.log('Query to playersPrivate complete. Documents found:', querySnapshot.size);

    if (!querySnapshot.empty) {
      const playerPrivateDoc = querySnapshot.docs[0];
      const playerPrivateId = playerPrivateDoc.id;
      // console.log('PlayerPrivate document found with ID:', playerPrivateId);

      // Step 2: Use playerPrivateId to look up the playerName in the players collection
      const playerDocRef = doc(db, 'players', playerPrivateId);
      const playerDoc = await getDoc(playerDocRef);
      // console.log('Player document lookup complete. Document exists:', playerDoc.exists());

      if (playerDoc.exists()) {
        user_slug.value = playerPrivateId;
        user_name.value = playerDoc.data().playerName;
        // console.log('User slug set to:', user_slug.value);
        // console.log('User name set to:', user_name.value);
      } else {
        // console.log('Player document not found in players. Setting user_slug and user_name to empty.');
        user_slug.value = '';
        user_name.value = '';
      }
    } else {
      // If not found in playersPrivate, check playerStage to see if they are still in onboarding
      // console.log('No matching playersPrivate document found. Checking playerStage...');
      const stageQuery = query(collection(db, 'playerStage'), where('userId', '==', _user.uid));
      const stageQuerySnapshot = await getDocs(stageQuery);
      // console.log('Query to playerStage complete. Documents found:', stageQuerySnapshot.size);

      if (!stageQuerySnapshot.empty) {
        // console.log('User is in onboarding (playerStage). Setting user_slug and user_name to null for now.');
        user_slug.value = null; // Indicates they are in the onboarding process
        user_name.value = null;
      } else {
        // console.log('User not found in playerStage. Setting user_slug and user_name to empty.');
        user_slug.value = '';
        user_name.value = '';
      }
    }
  } catch (error) {
    // console.error('Error fetching user data:', error);
    user_slug.value = '';
    user_name.value = '';
  }
};

// Listen for changes to authentication state
onAuthStateChanged(auth, (_user) => {
  // console.log('Auth state changed. User:', _user);
  user.value = _user;
  fetchUserData(_user);
});

const waitForUserSlug = async () => {
  const maxWaitTime = 5000; // Maximum wait time of 5 seconds
  const interval = 100;
  let waitedTime = 0;

  while (user_slug.value === '' && user_slug.value !== null && waitedTime < maxWaitTime) {
    console.log('Waiting for user_slug to be set...');
    await new Promise((resolve) => setTimeout(resolve, interval));
    waitedTime += interval;
  }

  // Check if the loop exited due to timeout
  if (waitedTime >= maxWaitTime) {
    console.error('Timeout waiting for user_slug to be set.');
    return 'not found'; // Return 'not found' to indicate the slug was not set
  }

  return user_slug.value;
};


const getUser = () => {
  return { user, user_slug, user_name, waitForUserSlug };
};

export default getUser;


