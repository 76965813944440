<template>
    <div class="ev">
        <div class="ev-block">
            <form v-if="documents" @submit.prevent>
                <h3><span style="font-weight: 500;">Messaging: </span>@{{ msgTo }}</h3>
                <div class="messageSend">
                    <textarea inputmode="text" autocomplete="off" placeholder="Type message then click send or press enter" v-model="message" @keypress.enter.prevent="handleSubmit"></textarea>
                    <div class="form-block">
                        <button v-if="!sendingMessage" @click="handleSubmit">Send <i class="fa-solid fa-paper-plane-top"></i></button>
                        <button v-else style="background-color: #cccccc;">Sending <i class="fa-solid fa-paper-plane-top"></i></button>
                    </div>
                </div>
                <div v-if="documents.length">
                    <div v-for="doc in documentsFormatted" :key="doc.id" class="alert">
                        <div :class="doc.fromId === msgToId ? 'messageThem' : 'messageMe'">
                            <div class="messageTimestamp">{{ doc.created }} </div>
                            <!-- x: {{ doc.from }}/{{ msgTo }} -->
                            <div class="messageText"><span style="font-weight:500;">{{ doc.from }}: </span>{{ doc.message }}</div>
                            <div v-if="doc.type == 'teamReq' && doc.fromId === msgToId" class="divButton" style="max-width: 120px;" @click="acceptTeam(doc.eventId, doc.fromId, doc.toId)">Accept</div>
                            <div v-else-if="doc.type == 'adminReq' && doc.fromId === msgToId" class="divButton" style="max-width: 120px;" @click="acceptAdmin(doc.locationId, doc.toId)">Accept</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import getMessages from "@/composables/getMessages"
import { formatDistanceToNow } from "date-fns"
import { computed, onUpdated, ref } from '@vue/runtime-core'
import getUser from "@/composables/getUser"
// import reloadPage from "@/composables/reloadPage"
// import addLog from "@/composables/addLog"
import { db, functions } from "@/firebase/config";
import { collection, addDoc, setDoc, doc, getDoc, serverTimestamp, query, where, getDocs } from "firebase/firestore";
import slugify from "slugify"
import updateMessagesReadAll from "@/composables/updateMessagesReadAll";
import { httpsCallable } from "firebase/functions";


export default {
    name: "show-message-thread",
    props: ["pName"],
    setup(props) {
        const { user, user_slug, user_name, waitForUserSlug } = getUser()
        // might need a new server side validation to convert what user types to slug?
        // set other user name/ID
        const msgTo = props.pName
        let msgToId = slugify(props.pName, {
            replacement: '-',
            remove: /[$*_+~.()'"!\-:@]/g,
            lower: true
        })
        const sendingMessage = ref(false)
        // need to get the other player's photo here.
        const playerPhotoURL = ref('https://firebasestorage.googleapis.com/v0/b/pickled-sandbox.appspot.com/o/images%2FPurplePickledLogo-Thumb.png?alt=media&token=4cff4290-084b-4cce-b513-1ab6e7a6f832')
        const getOpp = async (msgToId) => {
            const docRef = doc(db, "players", msgToId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                // console.log("PHto:", docSnap.data().photoURL);
                playerPhotoURL.value = docSnap.data().photoURL
            } else {
                // doc.data() will be undefined in this case
                // console.log("No such document!");
            }
        }
        getOpp(msgToId)
        // get my photo URL
        const myPhotoURL = ref('https://firebasestorage.googleapis.com/v0/b/pickled-sandbox.appspot.com/o/images%2FPurplePickledLogo-Thumb.png?alt=media&token=4cff4290-084b-4cce-b513-1ab6e7a6f832')
        const getMy = async (us) => {
            const docRef = doc(db, "players", us);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                // console.log("My PHOTO:", docSnap.data().photoURL);
                myPhotoURL.value = docSnap.data().photoURL
            } else {
                // doc.data() will be undefined in this case
                // console.log("No such document!");
            }
        }
        getMy(user_slug.value)

        // get last 99 texts between parties
        const { error, documents } = getMessages(user_slug.value, msgToId)

        const documentsFormatted = computed(() => {
            if (documents.value) {
                return documents.value.map(doc => {
                    let time = formatDistanceToNow(doc.created.toDate())
                    return { ...doc, created: time }
                })
            }
        })
        // update as read
        updateMessagesReadAll(msgToId + user_slug.value)



        // message send
        let toId = slugify(props.pName, {
            replacement: '-',
            remove: /[$*_+~.()'"!\-:@]/g,
            lower: true
        })

        const message = ref('')
        const handleSubmit = async () => {
            if (message.value) {
                sendingMessage.value = true
                let messageDoc = {
                    created: serverTimestamp(),
                    type: 'txt',
                    fromId: user_slug.value,
                    from: user_name.value,
                    toId: toId,
                    to: props.pName,
                    channel: user_slug.value + toId,
                    message: message.value,
                    read: false
                }
                // add to messages
                const addMessage = httpsCallable(functions, 'documentUpdates-addDocument')
                try {
                    await addMessage({
                        collection: "messages",
                        fields: messageDoc
                    })
                } catch (error) {
                    sendingMessage.value = false
                    console.error(`Error adding message in cloud function documentUpdates-addDocument`, error);
                }
                // addDoc(collection(db, "messages"), messageDoc)

                // add/update to last message
                //const docRef1 = doc(db, "messageLast", user_slug.value + toId);
                // update last first channel (me)
                const editMessageLast = httpsCallable(functions, 'documentUpdates-setDocument')
                try {
                    await editMessageLast({
                        collection: "messageLast",
                        id: user_slug.value + toId,
                        fields: {
                            created: serverTimestamp(),
                            type: 'txt',
                            fromId: user_slug.value,
                            from: user_name.value,
                            toId: toId,
                            to: props.pName,
                            channel: user_slug.value + toId,
                            message: '(' + user_name.value + '):' + message.value,
                            read: false,
                            channelPhotoURL: myPhotoURL.value ? myPhotoURL.value : ''
                        }
                    })
                } catch (error) {
                    sendingMessage.value = false
                    console.error(`Error updating messageLast in cloud function documentUpdates-setDocument`, error);
                }
                // setDoc(docRef1, {
                //     created: serverTimestamp(),
                //     type: 'txt',
                //     fromId: user_slug.value,
                //     from: user_name.value,
                //     toId: toId,
                //     to: props.pName,
                //     channel: user_slug.value + toId,
                //     message: '(' + user_name.value + '):' + message.value,
                //     read: false,
                //     channelPhotoURL: myPhotoURL.value ? myPhotoURL.value : ''

                // });

                // update last second channel
                //const docRef2 = doc(db, "messageLast", toId + user_slug.value);
                try {
                    await editMessageLast({
                        collection: "messageLast",
                        id: toId + user_slug.value,
                        fields: {
                            created: serverTimestamp(),
                            type: 'txt',
                            fromId: toId,
                            from: props.pName,
                            toId: user_slug.value,
                            to: user_name.value,
                            channel: toId + user_slug.value,
                            message: '(Me):' + message.value,
                            read: true, // becasue I sent this
                            channelPhotoURL: playerPhotoURL.value ? playerPhotoURL.value : ''
                        }
                    })
                } catch (error) {
                    sendingMessage.value = false
                    console.error(`Error updating messageLast second channel in cloud function documentUpdates-setDocument`, error);
                }
                // setDoc(docRef2, {
                //     created: serverTimestamp(),
                //     type: 'txt',
                //     fromId: toId,
                //     from: props.pName,
                //     toId: user_slug.value,
                //     to: user_name.value,
                //     channel: toId + user_slug.value,
                //     message: '(Me):' + message.value,
                //     read: true, // becasue I sent this
                //     channelPhotoURL: playerPhotoURL.value ? playerPhotoURL.value : ''
                // });
                message.value = ''
            }
            message.value = ''
            sendingMessage.value = false
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0; // always scroll to top on load

        const acceptTeam = async (eventId, p1, p2) => {
            // Ensure p1 is alphabetically before p2
            if (p1 > p2) {
                [p1, p2] = [p2, p1]; // Swap p1 and p2
            }

            // Query to check if a team already exists with the same eventId, p1, and p2
            const teamQuery = query(
                collection(db, "eventTeams"),
                where("eventId", "==", eventId),
                where("team", "array-contains-any", [p1, p2]),
                where("deleted", "==", false)
            );

            const querySnapshot = await getDocs(teamQuery);
            if (!querySnapshot.empty) {
                alert('Team already accepted');
                return;
            }

            // If the team does not exist, create a new document
            let teamDoc = {
                created: serverTimestamp(),
                deleted: false,
                eventId: eventId,
                p1: p1,
                p2: p2,
                team: [p1, p2]
            };

            await addDoc(collection(db, "eventTeams"), teamDoc);
            alert('Team formed!');
        };

        const acceptAdmin = async (locationId, pId) => {
            // Query to check if already an admin
            const adminQuery = query(
                collection(db, "locationsAdmin"),
                where("locationId", "==", locationId),
                where("playerId", "==", pId)
            );

            const queryAdminSnapshot = await getDocs(adminQuery);
            if (!queryAdminSnapshot.empty) {
                alert(`${pId} is already an admin.`);
                return;
            } else {
                if (confirm(`Do you wish to accept admin privileges for ${locationId}?`)) {
                    let adminDoc = {
                        created: serverTimestamp(),
                        locationId: locationId,
                        playerId: pId
                    };
                    await addDoc(collection(db, "locationsAdmin"), adminDoc);
                    alert(`Congratulations!  You are now an admin at ${locationId}!`);
                }
            }
        }
        return {
            error,
            documents,
            documentsFormatted,
            playerPhotoURL,
            message,
            handleSubmit,
            user_name,
            msgTo,
            msgToId,
            acceptTeam,
            acceptAdmin,
            sendingMessage
        }
    }

}
</script>

<style></style>