import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Reset from '@/views/Reset.vue'
import ViewPlayer from '@/views/ViewPlayer.vue'
import Alerts from '@/views/Alerts.vue'
import Messages from '@/views/Messages.vue'
import MessageThread from '@/views/MessageThread.vue'
import Upload from '@/views/Upload.vue'
import P404 from '@/views/P404.vue'
import { auth, db } from '@/firebase/config'
import ViewPlayers from '@/views/ViewPlayers.vue'
import Landing from '@/views/Landing.vue'
import { getDoc, doc } from 'firebase/firestore' // Firestore imports
import getUser from "@/composables/getUser"
import addPlayerLocation from "@/composables/addPlayerLocation"


const requireAuth = (to, from, next) => {
  let user = auth.currentUser;
  if (!user) {
    next({ name: 'landing', query: { redirect: to.fullPath } });
  } else {
    next();
  }
}

const signedIn = async (to, from, next) => {
  let user = auth.currentUser;
  if (!user) {
    next();
  } else {
    if (to.name === 'registerWithReferral' && to.params.referralCode) {
      const { user_slug } = getUser();
      const referralCode = to.params.referralCode;
      const locationDocRef = doc(db, 'locations', referralCode);
      const locationDoc = await getDoc(locationDocRef);
      if (locationDoc.exists()) {
        if (confirm(`Add ${referralCode} to your favorite court list?`)) {
          addPlayerLocation(user_slug.value, referralCode); // Assuming you have this function defined
          alert(`${referralCode} added to your favorite court list.`);
          next({ name: 'events', params: { locationId: referralCode } });
        } else {
          next({ name: 'home' });
        }
      } else {
        next({ name: 'home' });
      }
    } else {
      next({ name: 'home' });
    }
  }
}


// const signedIn = (to, from, next) => {
//   let user = auth.currentUser
//   if (!user) {
//     next()
//   } else {
//     next({ name: 'home' })
//   }
// }

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: requireAuth
  },
  {
    path: '/playersetup',
    name: 'playerSetup',
    component: () => import(/* webpackChunkName: "PlayerSetup" */ '@/views/PlayerSetup.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(/* webpackChunkName: "Welcome" */ '@/views/Welcome.vue'),
    beforeEnter: requireAuth
  },
  {
    // create event called with date path
    path: '/events/:locationId',
    name: 'events',
    // component: CreateEvent,
    component: () => import(/* webpackChunkName: "Events" */ '@/views/Events.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/landing',
    name: 'landing',
    props: true,
    component: Landing,
    beforeEnter: signedIn
  },
  {
    path: '/terms',
    name: 'terms',
    // component: Terms,
    component: () => import(/* webpackChunkName: "Terms" */ '@/views/Terms.vue'),
    beforeEnter: signedIn
  },
  {
    path: '/privacy',
    name: 'privacy',
    // component: Terms,
    component: () => import(/* webpackChunkName: "Privacy" */ '@/views/Privacy.vue'),
    beforeEnter: signedIn
  },
  {
    path: '/signin',
    name: 'signIn',
    props: true,
    // component: SignIn,
    component: () => import(/* webpackChunkName: "SignIn" */ '@/views/SignIn.vue'),
    beforeEnter: signedIn
  },
  {
    // register with manual referral code input
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "Register" */ '@/views/Register.vue'),
    beforeEnter: signedIn

  },
  {
    // register with automatic referral code input
    path: '/register/:referralCode',
    name: 'registerWithReferral',
    component: () => import(/* webpackChunkName: "Register" */ '@/views/Register.vue'),
    props: true,
    beforeEnter: signedIn
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    beforeEnter: signedIn

  },
  {
    // create event called with date / loc path
    path: '/createevent/:startDate/:locId',
    name: 'createEventWithDate',
    // component: CreateEvent,
    component: () => import(/* webpackChunkName: "CreateEvent" */ '@/views/CreateEvent.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    // create event called with NO date path
    path: '/createevent/',
    name: 'createEvent',
    // component: CreateEvent,
    component: () => import(/* webpackChunkName: "CreateEvent" */ '@/views/CreateEvent.vue'),
    beforeEnter: requireAuth
  },
  {
    // view edit event called with id
    path: '/vieweditevent/:evId',
    name: 'viewEditEventWithId',
    // component: ViewEditEvent,
    component: () => import(/* webpackChunkName: "ViewEditEvent" */ '@/views/ViewEditEvent.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    // view edit league called with id
    path: '/vieweditleague/:leagueSeriesId',
    name: 'vieweditleague',
    component: () => import(/* webpackChunkName: "ViewEditLeague" */ '@/views/ViewEditLeague.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    // players for location
    path: '/players/:locId',
    name: 'players',
    component: ViewPlayers,
    props: true,
    beforeEnter: requireAuth
  },
  {
    // players for location with admin filter
    path: '/players/:locId/:showAdmin',
    name: 'playersAdmin',
    component: ViewPlayers,
    props: true,
    beforeEnter: requireAuth
  },
  {
    // view edit event called with id
    path: '/editclub/:clubId',
    name: 'editclub',
    // component: ViewEditEvent,
    component: () => import(/* webpackChunkName: "EditClub" */ '@/views/EditClub.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/player',
    name: 'player',
    // component: Player,
    component: () => import(/* webpackChunkName: "Player" */ '@/views/Player.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/media',
    name: 'media',
    component: () => import(/* webpackChunkName: "Media" */ '@/views/ViewMedia.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/poppi',
    name: 'poppi',
    // component: Poppi,
    component: () => import(/* webpackChunkName: "Poppi" */ '@/views/ViewPoppi.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/player/:locId',
    name: 'playerSetHome',
    component: () => import(/* webpackChunkName: "Player" */ '@/views/Player.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/player/:locId/:addFavLoc',
    name: 'playerSetFav',
    component: () => import(/* webpackChunkName: "Player" */ '@/views/Player.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/player/settings/:scrollTarget?',
    name: 'playerSettings',
    component: () => import(/* webpackChunkName: "Player" */ '@/views/Player.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/viewplayer/:pName',
    name: 'viewplayer',
    component: ViewPlayer,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/venues',
    name: 'venues',
    props: true,
    component: () => import(/* webpackChunkName: "Venues" */ '@/views/Venues.vue'),
    beforeEnter: requireAuth
  },
  // {
  //   path: '/locations',
  //   name: 'locations',
  //   props: true,
  //   // component: Locations,
  //   component: () => import(/* webpackChunkName: "Locations" */ '@/views/Locations.vue'),
  //   beforeEnter: requireAuth
  // },
  {
    path: '/leagues/:locId',
    name: 'leagues',
    props: true,
    component: () => import(/* webpackChunkName: "Leagues" */ '@/views/ViewLeagues.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/locationedit/:lId',
    name: 'locationEdit',
    component: () => import(/* webpackChunkName: "Locations" */ '@/views/LocationEdit.vue'),
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "Support" */ '@/views/Support.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: Alerts,
    beforeEnter: requireAuth
  },
  {
    path: '/messages',
    name: 'messages',
    component: Messages,
    beforeEnter: requireAuth
  },
  {
    path: '/messagethread/:pName',
    name: 'messagethread',
    component: MessageThread,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/viewplayer/:pName',
    name: 'viewplayer',
    component: ViewPlayer,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/upload/:pId',
    name: 'upload',
    component: Upload,
    props: true,
    beforeEnter: requireAuth
  },  // catchall 404
  {
    path: '/leagues/:locId',
    name: 'leagues',
    props: true,
    component: () => import(/* webpackChunkName: "Leagues" */ '@/views/ViewLeagues.vue'),
    beforeEnter: requireAuth
  },


  {
    path: '/:catchAll(.*)',
    name: 'P404',
    component: P404

  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth', // Optional: enables smooth scrolling
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
