<template>
  <Smash :isVisible="!setPlayerName" labelText="Loading player..." labelsubtext="almost done"></Smash>
  <div v-if="setPlayerName">
    <PlayerNameSelect :uid="curUserId" @playerNameSet="handlePlayerNameSet" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import getUser from '@/composables/getUser'
import { db, auth } from '@/firebase/config'
import { doc, getDoc } from 'firebase/firestore'
import { signOut } from 'firebase/auth'
import PlayerNameSelect from '@/components/PlayerNameSelect.vue'
import Smash from '@/components/Smash.vue' // Adjust the path as necessary

export default {
  name: 'home',
  components: {
    PlayerNameSelect,
    Smash,
  },
  setup() {
    const router = useRouter()
    const { user, user_slug, user_name, waitForUserSlug } = getUser()
    const setPlayerName = ref(false)
    const curUserId = ref(user.value ? user.value.uid : null)

    const confirmPlayerReady = async () => {
      try {
        const docSnap = await getDoc(doc(db, 'players', user_slug.value))
        if (docSnap.exists()) {
          const locationFromCookie = ''
          if (docSnap.data().homeLocation && docSnap.data().skillSelf && docSnap.data().setupComplete) {
            const locationToUse = locationFromCookie || docSnap.data().homeLocation
            router.push({ name: 'events', params: { locationId: locationToUse } })
          } else if (docSnap.data().homeLocation && docSnap.data().skillSelf && !docSnap.data().setupComplete) {
            console.log('welcome')
            router.push({ name: 'welcome' })
          } else {
            console.log('player setup incomplete')
            router.push({ name: 'playerSetup' })
          }
        } else {
          console.log('Player not found!')
          signOut(auth)
          router.push({ name: 'landing' })
        }
      } catch (error) {
        console.error('An error occurred during player confirmation:', error)
      }
    }

    const handlePlayerNameSet = () => {
      console.log('handlePlayerNameSet')
      setPlayerName.value = false
      location.reload() // hacky solution
    }

    onMounted(async () => {
      // Wait for user_slug to be available
      const pf = await waitForUserSlug()

      if (pf === 'not found') {
        alert('Error (1A): Player not found!')
        await signOut(auth)
        router.push({ name: 'landing' })
      } else {
        // Now proceed based on user_name value
        if (user_name.value) {
          console.log('User name set, confirming player ready.')
          await confirmPlayerReady()
        } else {
          console.log('No player name set.')
          setPlayerName.value = true
        }
      }
    })

    return {
      setPlayerName,
      curUserId,
      handlePlayerNameSet,
    }
  },
}
</script>
